<template>
  <div style="background: rgb(7 27 28);" class="mt-16 py-16 px-12 text-center white--text">
    <div class="mx-auto">
      <h2 class="mb-2 mt-8"><span style="font-weight: 300">{{$t('prices.benefits.header')}}</span> {{$t('prices.benefits.header_bold')}}</h2>
      <hr style="width: 80px" class="mx-auto mb-16">
      <v-row style="max-width: 900px" class="mx-auto mb-8">
        <v-col>
          <h3 class="mb-2">{{$t('prices.benefits.1.header')}}</h3>
          <p class="body-2" style="font-weight: 300; min-width: 180px">{{$t('prices.benefits.1.text')}}</p>
        </v-col>
        <v-col>
          <h3 class="mb-2">{{$t('prices.benefits.2.header')}}</h3>
          <p class="body-2" style="font-weight: 300; min-width: 180px">{{$t('prices.benefits.2.text')}}</p>
        </v-col>
        <v-col>
          <h3 class="mb-2">{{$t('prices.benefits.3.header')}}</h3>
          <p class="body-2" style="font-weight: 300;  min-width: 180px">{{$t('prices.benefits.3.text')}}</p>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>
export default {
  name: "PriceBenefitsMobile"
}
</script>

<style scoped>

</style>